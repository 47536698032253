<template>
  <v-layout wrap v-if="content">
    <v-flex xs12>
      <v-select
        v-model="content.InteractiveMapsID"
        item-text="Name"
        item-value="ID"
        :items="interactiveMaps"
        label="ID"
        outlined
        @change="handleChangeIsModified"
        hide-details
        style="margin-bottom: 10px;"
      />
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import apiDtouch from '@/services/apiDtouch'
import utils from '@/services/utils'
export default {
  name: 'Form3Space',
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    content: null,
    interactiveMaps: [],
  }),
  computed: {        
    ...mapState('app', ['locale']),
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    getInteractiveMaps () {
      apiDtouch.getAll (`v2/private/workspaces/${this.workspaceID}/interactive-maps`)
        .then(response => {
          this.interactiveMaps = response.map(item => {
            return {
              ID: item.ID,
              Name: item.Name,
            }
          })
        })
    },
    prepareContent () {
      this.content = this.editedItem
      console.log(this.editedItem)
      this.getInteractiveMaps()
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
  },
}
</script>

